import { template as template_ce6526def5ec40c0a35e5e3c09774f5b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ce6526def5ec40c0a35e5e3c09774f5b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
