import { template as template_73b7ce3a65ef4fa8b86ccf93f0f96abb } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import icon from "discourse-common/helpers/d-icon";
const SidebarSectionLinkButton = template_73b7ce3a65ef4fa8b86ccf93f0f96abb(`
  <div class="sidebar-section-link-wrapper">
    <button
      {{on "click" @action}}
      type="button"
      class="sidebar-section-link sidebar-row --link-button"
      data-list-item-name={{@text}}
    >
      <span class="sidebar-section-link-prefix icon">
        {{icon @icon}}
      </span>

      <span class="sidebar-section-link-content-text">
        {{@text}}
      </span>
    </button>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionLinkButton;
