import { template as template_b0eeff3fccc54819be6ff25f5ba951c7 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { isNone } from "@ember/utils";
import { eq } from "truth-helpers";
import { NO_VALUE_OPTION } from "discourse/form-kit/lib/constants";
export default class FKControlSelectOption extends Component {
    get value() {
        return isNone(this.args.value) ? NO_VALUE_OPTION : this.args.value;
    }
    static{
        template_b0eeff3fccc54819be6ff25f5ba951c7(`
    {{! https://github.com/emberjs/ember.js/issues/19115 }}
    {{#if (eq @selected @value)}}
      <option
        class="form-kit__control-option --selected"
        value={{this.value}}
        selected
        ...attributes
      >
        {{yield}}
      </option>
    {{else}}
      <option
        class="form-kit__control-option"
        value={{this.value}}
        ...attributes
      >
        {{yield}}
      </option>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
