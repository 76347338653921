import { template as template_90aa89944eeb469f9217c89c96ebf6fe } from "@ember/template-compiler";
const WelcomeHeader = template_90aa89944eeb469f9217c89c96ebf6fe(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
