import { template as template_75528b959ef44fd581edb19a705c1ad4 } from "@ember/template-compiler";
const FKLabel = template_75528b959ef44fd581edb19a705c1ad4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
